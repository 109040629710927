@import "@/src/styles/common.scss";
@import "../../styles/variable.module.scss";
.counter-container {
    display: flex;
    align-items: center;
    @include use-font-normal();
    margin-left: 0.18rem;
    .counterWrap {
        display: flex;
        width: max-content;
        user-select: none;
        &.bordered {
            border: 0.02rem solid #707070;
            align-items: center;
            padding: 0.08rem 0.12rem;
        }
        &.disabledBorderWrap {
            background-color: #f0f0f0;
        }
        &.disabled {
            pointer-events: none;
        }
        // icon
        .icon_class {
            @include use-icon-small(null, #707070);
            // &.icon {
            // @media (min-width: 1280px) {
            //   &:hover {
            //     background-color: #f0f0f0;
            //   }
            // }
            // &.counterHandle {
            // &-small {
            //   padding: 10px;
            // }
            // // &-medium {
            // // 无padding
            // // }
            // &-large {
            //   padding: 14px;
            // }
            // }
            // }
            &.disabled {
                pointer-events: none;
                color: $gray_4;
            }
            &.leftSpacing {
                padding-right: 0.32rem;
            }
            &.rightSpacing {
                padding-left: 0.32rem;
            }
        }

        // input
        .centerAlign {
            display: flex;
            align-items: center;
            justify-self: center;
            &.noBorderValue {
                border: 0.02rem solid #ddd;
                @include use-font-title($text_3);
                width: 0.64rem;
                text-align: center;
                outline: none;
            }
            &.value {
                &-small {
                    width: 0.72rem;
                    @include use-font-normal();
                    font-family: var(--font-montserrat-medium);
                    border: none;
                    text-align: center;
                    outline: none;
                }
                &-medium {
                    text-align: center;
                    outline: none;
                }
                &-large {
                    width: 0.96rem;
                    @include use-font-normal();
                    font-family: var(--font-montserrat-bold);
                    border: none;
                    text-align: center;
                    outline: none;
                }
            }
            &.disabledBorderWrap {
                background-color: #f0f0f0;
            }
        }
    }
}
// input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
// }
