@import "@/src/styles/common.scss";
.search-article-skeleton-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.7rem;
    .search-article-item {
        margin-bottom: 0.4rem;
        span {
            display: block;
            p {
                font-size: 0.28rem;
            }
        }
        span + span {
            margin-top: 0.2rem;
        }
    }
}
