@import "@/src/styles/common.scss";
.basket-addon-item {
    position: relative;
    .no-share {
        position: absolute;
        top: -0.48rem;
        right: 0.2rem;
        .basket-remove-btn {
            // margin-left: 0.1rem;
            // padding-left: 0.2rem;
            color: $main;
            cursor: pointer;
        }
    }
    .pc-basket-item-img-container {
        text-align: center;
        position: relative;
        margin: 0 0 0.4rem;
        &.add-on-container {
            width: 5.43rem;
            margin: 0 auto 0.6rem;
        }
        .virtual-img {
            width: 5.6rem;
            margin: 0 auto;
        }
    }
    .pc-basket-item-detail {
        @include use-font-normal();
        .data-description {
            @include use-font-small();
            margin-bottom: 0.2rem;
        }
        .basket-total-price {
            @include use-font-title();
            letter-spacing: normal;
            text-transform: capitalize;
        }
        .basket-low-stock {
            color: #f69000;
            margin-top: 0.2rem;
        }
    }
    hr {
        background: $gray_4;
        margin: 0.4rem 0;
    }
}

.normal-font {
    @include use-font-normal();
}

.carousel-add-on-item-wrapper {
    .add-on-item-container {
        display: block;
        height: 5.3rem;
        padding: 0.16rem;
        border: 0.02rem solid $gray_4;
        margin: 0 auto;
        padding-bottom: 0.4rem;
        .add-on-item-img {
            margin: 0 auto;
            width: 100%;
            max-width: 100%;
            max-height: 2/08rem;
            min-height: 1.8rem;
            cursor: pointer;
            object-fit: contain;
        }
        .add-on-item-name {
            @include use-font-small();
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .basket-coupon-detail-btn {
            cursor: pointer;
            margin-left: 0.05rem;
            @include use-font-small($main);
        }
        .add-on-item-price {
            @include use-font-normal();
            margin-bottom: 0.4rem;
        }
        p {
            margin-bottom: 0.04rem;
        }
        .add-on-item-origin {
            color: $text_3;
            text-decoration: line-through;
        }
    }
}
