@import "@/src/styles/common.scss";
.AccountClass {
    text-align: left;
    padding: 0;
    @include use-font-normal();
    .text-center {
        text-align: center;
    }
    .product-head-img {
        max-width: 100%;
    }
}
.order-list-container {
    .set-loading-margin-zero {
        margin: 0;
    }
}
