@import "@/src/styles/common.scss";
.basket-checkout-btn-container {
    .basket-checkout-btn-container-fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 3;
        width: 100%;
        padding: 0.25rem 0.2rem;
        padding-top: 0;
        background: #fff;
        box-shadow: 0 0 5px 5px rgb(13 13 13 / 3%);
        .basket-checkout-fixed-price {
            font-size: 0.3rem;
            font-family: var(--font-montserrat-medium);
            text-align: center;
            line-height: 0.72rem;
        }
    }
    .basket-checkout-price {
        @include use-font-title();
    }
    .basket-checkout-continue-shopping {
        display: block;
        height: 0.7rem;
        margin: 0.2rem 0;
        text-align: center;
        @include use-font-normal($main);
        line-height: 0.7rem;
    }
}
