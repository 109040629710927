@import "@/src/styles/common.scss";
.help-center-skeleton-container {
    margin-top: 0.7rem;
    .help-center-item {
        padding: 0.4rem 0.36rem 0.38rem;
        border-bottom: 0.02rem solid $gray_4;
        display: flex;
        justify-content: space-between;
    }
}
