@import "@/src/styles/common.scss";
.lens-detail-card {
    margin-bottom: 0.3rem;
    margin-top: 0.1rem;
    table {
        width: 100%;
        border: 0.02rem solid $main;
        text-align: center;
        border-collapse: collapse;
        vertical-align: middle;
        background: rgba(38, 171, 255, 0.1);
        tbody,
        thead {
            vertical-align: middle;
            tr {
                display: table-row;
                vertical-align: inherit;
                border-color: inherit;
                td,
                th {
                    border: 0.02rem solid $main;
                    height: 0.6rem;
                }
            }
        }
        thead {
            th {
                @include use-font-small();
                text-align: center;
            }
        }
        tbody {
            td:first-child {
                @include use-font-small();
            }
        }
    }
}
