@import "@/src/styles/common.scss";
.exchange-refund-class {
}
// component-main
.refund-container {
    .title-container {
        justify-content: flex-start;
        background-color: #fff;
        margin-bottom: 0.2rem;
        display: flex;
        align-items: center;
        .title {
            text-align: left;
            margin: 0;
            @include use-font-normal();
        }
        .type-refund-exchange {
            display: flex;

            .type-choose {
                margin: 0 0.1rem;
            }
        }
    }
    .product-list {
        .title {
            @include use-font-normal();
        }
        .product-item {
            padding: 0.4rem 0;
            border-bottom: 0.02rem solid $gray_4;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .product-show {
                justify-content: space-between;
                align-items: flex-start;
                flex-direction: column;
                .checkbox-rx {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    .product-checkbox {
                        .product-label {
                            font-size: 0.28rem;
                        }
                    }
                    .prescription-btn {
                        width: auto;
                        min-width: unset;
                        padding: 0;
                        height: auto;
                        margin-left: 0.1rem;
                        line-height: 2;
                    }
                }
                .product-image-container {
                    width: 2.6rem;
                    min-height: 1.3rem;
                    padding-left: 0.5rem;
                    .posted-content {
                        justify-content: space-between;
                    }
                }
            }
            .qtychange-container {
                flex-direction: column;
                align-items: flex-end;
                .counter-content {
                    justify-content: flex-start;
                    .qty {
                        margin: 0;
                        margin-right: 0.1rem;
                    }
                }
            }
        }
    }
    .next-container {
        justify-content: flex-end;
        padding: 0.2rem;
        padding-bottom: 0.4rem;
        background-color: #fff;
    }
}

// component-reason
.reason-container {
    .title {
        text-align: left;
        margin-bottom: 0.4rem;
    }
    .title ~ .reson-list {
        border-top: 0.02rem solid $gray_4;
        .icon-right-container {
            justify-content: space-between;
            border-bottom: 0.02rem solid $gray_4;
            padding: 0.3rem 0;
            height: auto;
            min-height: unset;
            @include use-font-normal();
            line-height: 1;
            .button-content-cotainer {
                width: 100%;
                .icon-right {
                    @include use-icon-small();
                    position: absolute;
                    right: 0;
                    width: auto;
                    top: 0;
                }
            }
        }
    }
}

// compoennt-frame-panel
.refund-frame {
    flex: 1;
    overflow-y: scroll;
    &.has-back {
    }
    .reason-item + .reason-item {
        margin-top: 0.4rem;
    }
    .reason-item {
        .title-text {
            @include use-font-normal();
        }
        .radio-container {
            margin-top: 0.4rem;
        }
        .item-container {
            padding: 0;
            margin: 0;
        }
    }
    .item-container {
        padding: 0.4rem 0.2rem;
        background: #fff;
        margin-bottom: 0.2rem;
        .title-text {
            margin-bottom: 0.2rem;
            .checked-line {
                padding: 0.2rem 0;
            }
        }
        &.refund-textarea {
            p {
                @include use-font-normal();
                margin-bottom: 0.2rem;
            }
            textarea {
                width: 100%;
                height: 80px;
                border: 0.02rem solid $gray_4;
                resize: none;
                font-size: 0.24rem;
                padding: 0.1rem 0.2rem;
            }
        }
    }
}

.img {
    max-width: 0.64rem;
    max-height: 0.64rem;
    margin-right: 0.07rem;
}

// common-title
.refund-title.title-container.refund-title.title-container {
    margin: 0 0 0.6rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: $gray_4 solid 0.02rem;
    position: fixed;
    width: 100%;
    .back {
        width: auto;
        min-width: unset;
        display: flex;
        align-items: center;
        @include use-font-title();
        line-height: 1;
        position: fixed;
        top: 0.3rem;
        left: 0.3rem;
        z-index: 1;
        .back-icon {
            @include use-icon-small();
            margin-right: 0.2rem;
        }
    }
    .title {
        @include use-font-title();
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center; // flex-start
        align-items: center;
        height: auto;
        font-size: 0.4rem;
    }
    .close-icon {
        @include use-icon-normal();
        position: fixed;
        top: 0.3rem;
        right: 0.3rem;
    }
}

// component-prescription-panel
.refund-frame.prescription-panel {
    flex: 1;
    overflow-y: scroll;
    .prescription-title {
        margin-bottom: 0.3rem;
    }
    .render-data-container {
        .checked-line {
            padding: 0.3rem 0;
            border-top: 0.02rem solid $gray_4;
            .choose-item-container {
                justify-content: flex-start;
                flex-wrap: wrap;
                .choose-item {
                    margin-right: 0.4rem;
                    margin-top: 0.2rem;
                }
            }
        }
    }
    .upload-picture {
        margin-bottom: 0;
        margin-top: 0.3rem;
    }
    .hr-line {
        margin: 0.2rem 0;
        background-color: $gray_4;
        width: 100%;
        height: 0.02rem;
        display: block;
    }
    .site-name {
        margin-bottom: 0;
        margin-top: 0.3rem;
    }
}

// common-btnline
.btn-line-container {
    padding: 0.2rem 0 0.4rem;
    flex-direction: column;
    .btn-line-button {
        width: 3.73rem;
        border-radius: 0.08rem;
        height: 0.7rem;
        line-height: 0.7rem;
    }
}

.submit-succesfully-dialog-container {
    .submit-succesfully {
        padding-bottom: 0.4rem !important;
        border-bottom: 0.02rem solid #ddd;
    }
}

.children-drawer-container {
    padding: 0 !important;
    .refund-frame,
    .reason-container,
    .refund-container {
        height: calc(100% - 1rem);
        margin-top: 1rem;
        overflow: auto;
        padding: 0.6rem 0.4rem 0;
    }
}
