@import "@/src/styles/common.scss";
.accordion-container {
    .accordion_details.accorion_children_container {
        // 千万不能塞到root-expanded里面去，展开才会有的
        .classify-panel {
            .language_p {
                text-align: left;

                font-family: var(--font-montserrat);
            }
        }
    }
}
