@import "@/src/styles/common.scss";
.help-tips {
    padding-top: 0.2rem;
    .notice-container {
        display: flex;
        align-items: flex-start;
        @include use-font-normal();
        margin-bottom: 0.4rem;
    }
    .icon-wrapper {
        display: flex;
        align-items: flex-start;
        @include use-font-normal();
    }
    .icon-wrapper + .icon-wrapper {
        margin-top: 0.05rem;
    }
    .icon-track {
        @include use-icon-normal();
    }
    .icon-items {
        color: $text !important;
        padding-right: 0.2rem;
        @include use-icon-normal(!important);
    }
}
