@import "@/src/styles/common.scss";
// link & image

.nav-wrapper {
    .img_container.image-wrapper {
        border-top: 0.02rem solid $gray_4;
        display: flex !important;
        width: 100%;
        padding: 0.4rem 0 0;
        justify-content: flex-start;
        margin: 0.42rem 0 0 0;
        flex-wrap: wrap !important;
        .image_content {
            width: calc((100% - 0.4rem) / 3);
            margin: 0;
            text-align: center;
        }
        // 间距0.2rem
        .image_content:nth-of-type(3n + 2),
        .image_content:nth-of-type(3n + 3) {
            margin-left: 0.2rem;
        }
        // 4以后的图片整体要有0.3间距（这个间距是我编的）
        .image_content:nth-of-type(3n + 4),
        .image_content:nth-of-type(3n + 5),
        .image_content:nth-of-type(3n + 6) {
            margin-top: 0.3rem;
        }
        .img_link {
            display: inline-block;
        }
        .img_title {
            width: 100%;
            font-size: 0.28rem;
            font-family: var(--font-montserrat);
            color: $text;
            text-align: center;
            margin-top: 0.2rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
    }
}

.fm_link {
    &.normal_link {
        color: inherit;
        width: 100%;
        justify-content: flex-start;
        font-size: inherit;
        font-family: inherit;
    }
    &:hover {
        background-color: inherit;
        outline: none;
        text-decoration: underline;
        color: $text_3;
    }
}
