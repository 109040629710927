@import "@/src/styles/common.scss";
.virtual-card-wrapper {
    text-align: center;
    padding: 0.3rem 0;
    .virtual-card-content {
        justify-content: space-between;
        align-items: flex-start;
        .virtual-card-img {
            width: 2.8rem;
            min-height: 2rem;
            margin-right: 0.2rem;
            flex-shrink: 0;
        }
        .virtual-card-right {
            text-align: left;
            @include use-font-normal();
            .description {
                @include use-font-small();
                color: $text_3;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 这里是超出几行省略 */
                overflow: hidden;
            }
            .coupon-description {
                @include use-font-small();
                color: $text_3;
                margin-top: 0.1rem;
                margin-bottom: 0.2rem;
            }
        }
    }
}

.modal-wrapper {
    .virtuals-container {
        text-align: center;
        .virtuals-description {
            @include use-font-small();
        }
        .virtuals-txt {
            @include use-font-small();
            margin-top: 0.4rem;
            text-align: left;
        }
    }
}
