@import "@/src/styles/common.scss";
// common

.bt {
    max-width: 3rem;
    height: 0.72rem;
    line-height: 0.72rem;
    border: none;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    font-size: 0.26rem;
    padding: 0 0.24rem;
    transition: all 0.15s linear;
    margin: 0;
}
.serviceTypeOption {
    display: inline-block;
    border-radius: 3px;
}
.ChangeReturnMobileStyle {
    width: calc(100% - 30px);
    .input-group {
        margin-bottom: 10px;
        display: flex;
        align-items: flex-start;
        // left
        .input-group-left {
            width: 1.7rem;
        }
        // right
        .input-group-right {
            width: 100%;
            .label-tag {
                background-color: #31c1da;
                color: #fff;
                display: inline-block;
                padding: 0 0.1rem;
                border-radius: 3px;
            }
            .serviceProductSelect {
                margin: 0 -0.15rem;
                &::after {
                    clear: both;
                    display: table;
                    content: " ";
                }
            }
            // textarea
            .problem_description {
                width: 100%;
                border-top: 0.02rem solid #ccc;
                height: 0.6rem;
            }
        }

        // button
        .submit-btn {
            background-color: dark;
        }
    }

    .serviceProductOption {
        padding: 0 0.15rem 0.15rem;
        box-sizing: border-box;
        float: left;
        width: 50%;
    }
    .serviceProductOption input:checked + div {
        border: 0.02rem solid #ccc;
    }
    .serviceProductOption > div {
        border: 0.02rem solid #fff;
    }

    .input-group-left div {
        width: 1.7rem;
        white-space: initial;
    }
    .iRadioSpan {
        width: 0.3rem;
        height: 0.3rem;
    }
    textarea[name="problem_description"] {
        height: 80px !important;
        width: 100%;
    }
}
.cr_btn_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ChangeReturnPCStyle .serviceProductSelect:after {
    clear: both;
    display: table;
    content: " ";
}
.ChangeReturnPCStyle .C_bt {
    padding: 0 15px;
    max-width: 200px;
}
.ChangeReturnPCStyle .serviceProductSelect {
    margin: 0 -5px;
}
.ChangeReturnPCStyle .serviceProductOption {
    padding: 0 5px 15px;
    box-sizing: border-box;
    float: left;
    width: 33%;
}
.ChangeReturnPCStyle .serviceProductOption input:checked + div {
    border: 0.02rem solid #ccc;
}
.ChangeReturnPCStyle .serviceProductOption > div {
    border: 0.02rem solid #fff;
}
.ChangeReturnPCStyle .serviceTypeOption {
    display: inline-block;
    border-radius: 3px;
}
.ChangeReturnPCStyle .input-group-left {
    width: 100px;
}
.ChangeReturnPCStyle .C_iCheckbox {
    font-family: var(--font-montserrat);
}
.ChangeReturnPCStyle .input-group-left div {
    width: 100px;
    white-space: initial;
}
