@import "@/src/styles/common.scss";
.search-order-list-container {
    .search-order-item {
        span {
            transform: none;
        }
        .order-title {
            display: flex;
            justify-content: space-between;
            padding: 0.2rem 0px 0.1rem;
        }
        .order-img {
            margin-top: 0.3rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            border-top: 0.02rem dashed #eee;
            border-bottom: 0.02rem dashed #eee;
            padding: 0.1rem 0;
            span {
                display: block;
            }
            .price {
                margin-left: 1.2rem;
                height: min-content;
            }
            .product-title {
                margin-top: 0.1rem;
            }
        }
        .show-details {
            display: flex;
            flex-direction: column;
            span {
                margin-top: 0.1rem;
            }
        }
        .button-container {
            display: flex;
            margin-top: 0.3rem;
            span {
                display: inline-block;
                transform: none;
                margin: 0 0.15rem 0.15rem 0;
                div {
                    height: 0.7rem;
                    padding: 0 0.1rem;
                }
            }
        }
    }
}
