@import "@/src/styles/common.scss";
.hot-search-container {
    display: flex;
    flex-wrap: wrap;
}

.search-products-skeleton-container {
    font-size: 0.28rem;
    line-height: 1.5;
    font-family: var(--font-montserrat);
    margin-top: 0.7rem;
    span {
        display: block;
    }
    .products-container {
        margin-bottom: 0.4rem;
    }
    .hot-product-container {
        .base-sellers-skeleton {
            margin-bottom: 0.2rem;
            .base-sellers {
                height: auto;
            }
        }
        .product_data_wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .single-img-skeleton {
                width: 45%;
                margin-bottom: 0.4rem;
                .rectangular-img {
                    height: calc(36vw / 2);
                    display: block;
                }
            }
        }
    }
}
