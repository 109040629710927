@import "@/src/styles/common.scss";
.box {
    position: relative;
}

.size-guide-btn {
    position: absolute;
    top: 0;
    right: 0.3rem;
}

.filter-find-my-fit {
    width: 100%;
    margin: 0;
}
