@import "@/src/styles/common.scss";
.basket-recommended-list {
    // margin-top: 0.2rem;
    .title {
        font-size: 0.36rem;
        font-family: var(--font-montserrat-medium);
        margin-bottom: 0.4rem;
        padding-left: 0.2rem;
    }
    .basket-recommended-content {
        background: #fff;
        padding: 0 0.2rem;
    }
}
