@import "@/src/styles/common.scss";
.referal-share-container {
    padding-bottom: 0.4rem;
    border-bottom: 0.02rem solid $gray_4;
    margin-bottom: 0.4rem;
    .share-title {
        font-size: 14px;
        .title-span {
        }
    }
    .share_wrap {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        .icon_box {
            transition: all 0.3s;
            margin-left: 5px;
            width: 0.72rem;
            height: 0.72rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            &.email-link {
                margin-left: 0;
            }
            &:hover {
                transform: translateY(-0.2rem);
            }
            .email-full {
                font-size: 0.44rem;
                color: $text;
            }
        }
    }
}
