@import "@/src/styles/common.scss";
.filter-nav-main {
    position: relative;
    height: 1rem;
    display: flex;
    flex-direction: column;
    .filter-nav-bar {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.2rem;
        width: 100%;
        height: 1rem;
        font-size: 0.26rem;
        color: $text;
        box-sizing: border-box;
        background: #fff;
        border-bottom: 0.02rem solid #ccc;
        z-index: 9;
    }
    .filter-nav-bar-fixed {
        top: 0;
        z-index: 11;
    }
    .filter-nav-bar-two-nav {
        top: 1rem;
        z-index: 5;
    }
    .sort-filter {
        white-space: nowrap;
        font-family: var(--font-montserrat-medium);
        font-size: 0.32rem;
        padding: 0.1rem 0.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: #000 0.02rem solid;
        border-radius: 0.1rem;
        .filter-number {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0.35rem;
            font-size: 0.24rem;
            border-radius: 50%;
            background-color: #efefef;
            color: #000;
            margin-left: 0.4rem;
            &.active {
                background-color: $main;
                color: #fff;
            }
        }
    }
    .results-switch {
        display: flex;
    }
    .choose-show-col-icon {
        display: flex;
        align-items: center;
        margin-left: 0.2rem;
        .show-dubble,
        .show-single {
            @include use-icon-normal();
        }
        .show-dubble {
            margin-left: 0.3rem;
        }
        &.single-col {
            .show-single {
                color: $main;
            }
            .show-dubble {
                color: $text;
            }
        }
        &.dubble-col {
            .show-single {
                color: $text;
            }
            .show-dubble {
                color: $main;
            }
        }
    }
    .filter-btn {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        p {
            @include use-font-title(#121a24);
            margin: 0;
        }

        .active {
            color: $main;
        }
    }
    .filterIcon {
        @include use-icon-small();
        margin-right: 0.1rem;
    }
    .results {
        display: flex;
        align-items: center;
        font-size: 0.28rem;
        margin-right: 0.1rem;
    }
}

.title {
    height: 100%;
    display: flex;
    align-items: center;
    transition:
        background-color 2s,
        color 0.3s;

    .title-p {
        @include use-font-title();
        white-space: nowrap;
        overflow: hidden;
        max-width: 85%;
        text-overflow: ellipsis;
    }
    .panel-container {
        transition: transform 0.3s ease 0s;
        .slider-panel-icon {
            @include use-icon-small();
            margin-right: 0.12rem;
            margin-left: 0;
            transition: transform 0.3s;
        }
        &.panel-rotate {
            margin-left: 0.12rem;
            margin-right: 0;
        }
    }
}
