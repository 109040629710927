@import "@/src/styles/common.scss";
@mixin darkButton() {
    width: auto !important;
    border: 0.02rem solid #000 !important;
    border-radius: 0 !important;
    @include use-font-small($text, !important);
    &:hover {
        background-color: #000 !important;
        color: #fff !important;
    }
}
.order-item-container {
    .order-title-container {
        padding: 0.2rem 0 0.1rem;
        display: flex;
        justify-content: space-between;
        .orders_oid {
            padding-right: 0.2rem;
        }
        .account-status {
            text-decoration: underline;
        }
        .order-time {
            flex-grow: 1; // 模块拉伸 flex-shrink模块收缩
        }
    }
    .order-products-container {
        .product-item {
            margin-top: 0.3rem;
            display: flex;
            align-items: center;
            .product-image-container {
                width: 100%;
                padding-right: 1.2rem;
                text-align: center;
                .image-content {
                    min-height: 2.8rem;
                }
            }
        }
    }
    .delivery-container {
        padding: 0.2rem 0;
        border-top: 0.02rem dashed #eee;
        border-bottom: 0.02rem dashed #eee;
    }
    .afterSales {
        // margin: 0 -0.15rem;
        padding: 0.3rem 0.15rem 0;
        flex-wrap: wrap;
        display: flex;
    }
    .afterSale {
        margin-bottom: 0.2rem;
        padding: 0 0.15rem 0.15rem 0;
    }
    .afterSale button,
    .afterSale a {
        padding: 0 0.1rem;
        font-size: 0.2rem;
    }

    // dark-btn
    .view-reorder-wrapper {
        .track-action-link {
            height: 0.6rem;
            display: block;
            text-align: center;
            line-height: 0.6rem;
        }
        // button
        .view-btn,
        .reorder-btn,
        .track-action-link,
        .cancel-order,
        .payment-failed,
        .handle-exchange,
        .can-be-comment {
            @include darkButton();
            &.track-action-link {
                line-height: 0.6rem !important;
            }
        }
    }
}

.toast-content {
    .icon-clear-style {
        font-size: 0.8rem;
        display: block;
        margin: 0 auto 0.32rem;
    }
}
