@import "@/src/styles/common.scss";
.tabs-container {
    .tabs-wrapper-container {
        height: 1.04rem !important;
    }
    .tab-panel-container {
        padding: 0 0.3rem;
        &.helpcenter {
            padding: 0;
        }
        .search-error {
            padding: 0.2rem 0px;
            min-height: 0.7rem;
            color: $warning;
        }
    }
}
.search-drawer-container {
    .search-drawer-box {
        padding: 0 !important;
        .header-modals {
            width: 100%;
            padding: 0;
            .search-input-container {
                width: 100%;
                height: 100%;
                padding: 0.16rem 0.2rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .arrow-left {
                    font-size: 0.48rem;
                }
                .search-picker-and-input {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    margin-left: 0.3rem;
                    width: 100%;
                    .root-input-here {
                        width: 100%;
                    }
                }
            }
        }
        .search-drawer-children-content {
            padding: 0 !important;
            .tab-root {
                padding-top: 0.34rem;
            }
        }
    }
}
