@import "@/src/styles/common.scss";
$iconColor: #707070;
@import "../_common.module.scss";
@mixin defaultFont() {
    box-shadow: none;
    font-family: var(--font-montserrat);
    line-height: 1.5;
    padding: 0;
    margin: 0;
}
@mixin defaultTitle() {
    font-size: 0.32rem;
    padding: 0.4rem 0.2rem 0.38rem;
    border-bottom: 0.02rem solid $gray_4;
    color: $text;
    height: auto;
    min-height: unset;
    font-family: var(--font-montserrat-bold);
}
.no-accordion-title {
    @include defaultFont();
    @include defaultTitle();
}
.accordion_root.addIndex {
    @include defaultFont();
    &::before {
        background: none; // 覆盖accordion对应div的before设置底线的问题
    }
    .accordion_title {
        @include defaultTitle();
        .title-content {
            margin: 0;
            font-size: inherit;
            font-family: inherit;
        }
        .expand-icon {
            @include use-icon-normal(null, $iconColor);
        }
    }
    // details
    .children_container {
        margin: 0;
        padding: 0.44rem 0.4rem 0.36rem;
        font-size: 0.28rem;
        color: $text;
        .child-font {
            width: 100%;
            color: inherit;
            display: block;
            font-size: inherit;
            line-height: 1.5;
            text-align: left;
        }
        & > * + *,
        .child-font + .child-font {
            margin-top: 0.38rem;
        }
        &.hidden-bottom-line {
            margin: 0;
            padding: 0.16rem 0.32rem 0.2rem;
        }
        .content {
            background-color: gray;
        }
    }
    box-shadow: none;
    &.expanded {
        margin: 0;
    }
}
