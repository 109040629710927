@import "@/src/styles/common.scss";
.vat-notice {
    @include use-font-small();
    text-align: left;
    .red-vat {
        color: red;
        margin: 0 0.1rem;
        font-family: var(--font-montserrat-medium);
    }
}
