@import "@/src/styles/common.scss";
.basket-pay-notice-container {
    padding-bottom: 0.6rem;
    text-align: center;
    p {
        text-align: left;
    }
    .basket-checkout-accept {
        @include use-font-normal();
        margin-top: 0.4rem;
    }
    .basket-pay-notice-img {
        width: 100%;
        margin-top: 0.4rem;
    }
    .basket-pay-notice-text {
        @include use-font-small($text_3);
        margin-top: 0.2rem;
    }
}
